import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { BLTIcon } from "./BLT_Icon";

const initialState = {
  IconColorClassName: 'home-lighttext-color',
  Text: 'home-text-color',
  design: 'home-surface-color',
  about: 'home-light-color'
}

const HomeHeader = props => {
  const [classes, setClasses] = useState(initialState);
  const { pathname } = useLocation();

  const getClassnames = (pathname) => {
    switch (pathname) {
      case '/': return setClasses({ BackButtonMobile: 'hide', InfoIconMobile: 'home-light-color2', Text: 'home-light-color', IconColorClassName: 'hide', design: 'home-surface-color', Home: 'home-text-color', about: 'home-light-color',  });
      case '/UX/process': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide',  });
      case '/UX/productDefinition': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide' });
      case '/UX/userResearch': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide' });
      case '/UX/Analysis': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide' });
      case '/UX/Design': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide' });
      case '/UX/Build': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-lighttext-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide' });
      case '/ebay': return setClasses({ BackButtonMobile: 'ebay-light-color', InfoIconMobile: 'hide', Text: 'ebay-lighttext-color', IconColorClassName: 'ebay-fill-color', design: 'ebay-surface-color', about: 'hide', });
      case '/gatorade': return setClasses({ BackButtonMobile: 'gatorade-light-color', InfoIconMobile: 'hide', Text: 'gatorade-lighttext-color', IconColorClassName: 'gatorade-fill-color', design: 'gatorade-surface-color', about: 'hide', });
      case '/lrt': return setClasses({ BackButtonMobile: 'lrt-light-color', InfoIconMobile: 'hide', Text: 'lrt-lighttext-color', IconColorClassName: 'lrt-fill-color', design: 'lrt-surface-color', about: 'hide', });
      case '/dentalportal': return setClasses({ BackButtonMobile: 'dental-lighttext-color', InfoIconMobile: 'hide', Text: 'dental-lighttext-color', IconColorClassName: 'dental-fill-color', design: 'dental-surface-color', about: 'hide', });
      case '/casestudy': return setClasses({ BackButtonMobile: 'dental-lighttext-color', InfoIconMobile: 'hide', Text: 'dental-lighttext-color', IconColorClassName: 'dental-fill-color', design: 'dental-surface-color', about: 'hide', });
      case '/felt': return setClasses({ BackButtonMobile: 'felt-light-color', InfoIconMobile: 'hide', Text: 'felt-lighttext-color', IconColorClassName: 'felt-fill-color', design: 'felt-surface-color', about: 'hide', });
      case '/finplan': return setClasses({ BackButtonMobile: 'finplan-light-color', InfoIconMobile: 'hide', Text: 'finplan-lighttext-color', IconColorClassName: 'finplan-fill-color', design: 'finplan-surface-color', about: 'hide', });
      case '/shoe': return setClasses({ BackButtonMobile: 'shoe-light-color', InfoIconMobile: 'hide', Text: 'shoe-lighttext-color', IconColorClassName: 'shoe-fill-color', design: 'shoe-surface-color', about: 'hide', });
      case '/hyperconnect': return setClasses({ BackButtonMobile: 'hyper-light-color', InfoIconMobile: 'hide', Text: 'hyper-lighttext-color', IconColorClassName: 'hyper-fill-color', design: 'hyper-surface-color', about: 'hide', });
      case '/about': return setClasses({ BackButtonMobile: 'home-light-color', InfoIconMobile: 'hide', Text: 'home-light-color', IconColorClassName: 'home-fill-color', design: 'home-surface-color', about: 'hide', });
      case '/pixel': return setClasses({ BackButtonMobile: 'android-lighttext-color', InfoIconMobile: 'hide', Text: 'android-lighttext-color', IconColorClassName: 'android-fill-color', design: 'android-surface-color', about: 'hide', });
      case '/AuthPage': return setClasses({ BackButtonMobile: 'android-lighttext-color', InfoIconMobile: 'hide', Text: 'android-lighttext-color', IconColorClassName: 'android-fill-color', design: 'android-surface-color', about: 'hide', });
      default: return classes;
    }
  }
  useEffect(() => {
    getClassnames(pathname.toLowerCase());
  }, [pathname])

  return (
    <header>      
      <nav id="fixedMenu">

        <Link className={`header-left`} to="/">
          <BLTIcon className={`${classes.Text} difference`} />
        </Link>

        <li>
          <Link className={`${classes.Text} ${classes.Home}  header-right`} to="/">
            <h2 className={` ${classes.BackButtonMobile} weighty`}> <span className={`${classes.IconColorClassName} difference all material-icons-48 hidemobile`}>keyboard_return</span> All </h2>
          </Link>
        </li>

        <li>
          <Link className={`header-right`} to="/about">
          <h2 className={` ${classes.Text} ${classes.InfoIconMobile} difference weighty`}>  Info </h2>
          </Link>
        </li>

      </nav>
    </header>
  );
};

export default HomeHeader;
