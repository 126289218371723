import React from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { lrtData } from "../../../data/app_data";
import { ImageRef } from "../../Images/ImageRef";
import ImageWithDesc from "../../Images/ImageWithDesc";

const lrt = props => {
  let Images = lrtData.slice(0, 1).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images13 = lrtData.slice(1, 3).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images35 = lrtData.slice(3, 5).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images57 = lrtData.slice(5, 7).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let LRTapp1 = lrtData.slice(7, 9).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let LRTapp2 = lrtData.slice(9, 11).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let Flow = lrtData.slice(11, 12).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  return (
    <Aux>
      <Helmet>
        <title>BLT - LRT</title>
        <meta name="description" content="Living Room Theaters Site" />
        <html className="lrt-background-color"/>
      </Helmet>
      <div className="lrt-background-color">
          <div className="l-w1 m-projCopy animated">
          <h1 className="m-quote topper lrt-lighttext-color">
         Theater<br/>tickets
          </h1>
          </div>
        <div className="l-w1 l-flexbox-alldevs projTitle">
        <div className="introList animated delay3">
        <li className="abcd lrt-surface-color lrt-light-color">2017</li>
        <li className="abcd lrt-surface-color lrt-light-color">8 Months</li>
          <li className="abcd lrt-surface-color lrt-light-color">Theater (SM)</li>
          <li className="abcd lrt-surface-color lrt-light-color hidemobile">UX UI</li>
            </div>
            </div>
                <div className="l-w1 l-flexbox m-projCopy animated delay">
          <div className="m-twoColumnCopyA pad-Bot-60">
            {/*<h2 className="m-projTitleGroup lrt-lighttext-color">Scope</h2>*/}
            <p className="m-projCopy-top lrt-lighttext-color">
LRT's site didn't offer seat reservation, gift card functionality,refund or exchange tickets, preorder food/drink, or seat reservation for its irregular theaters. The solve was a responsive modern UI with fresh flows to meet this gaps. </p>
          </div>
        </div>
      {/*  <div className="l-w1 animated delay2">
          <h4 className="m-projTitle small center">User Research</h4>
          <p className="m-projCopy l-2cl">
          Interviews conducted with users revealed their difficulty/confusion
          with selecting theater locations (LRT operates in two cities -
          Portland, Oregon and Boca Raton, Florida). As well, these interviews
          revealed that around half of the site's current users access the site
          from a desktop, with the other half accessing from a mobile device
          (tablet users accounted for around 10% of the user group).
          Additionally, users experience difficulty with exchanging or
          cancelling tickets, and have defaulted to calling theater offices,
          which is a burden on staff. Users have also requested a feature to be
          able to pre-order food along with their tickets, to have food ready
          when they arrive to the theater.
        </p>
        </div>
          {Images11}
        */}

        <div className="l-w1 pad-Bot-60 animated delay ">
            <div className="feature_set">
            <table>
  <tr>
      <th className="lrt-surface-color st-lrt"> <p className="table lrt-light-color "> Multiple <br/> Theaters </p></th>
      <th className="lrt-surface-color st-lrt"> <p className="table lrt-light-color ">Book <br/> Seats </p></th>
      <th className="lrt-surface-color st-lrt"> <p className="table lrt-light-color "> Pre-order <br/> Food</p></th>
  </tr>
  <tr>
      <td className="lrt-surface-color st-lrt"> <p className="table lrt-light-color "> Refund/ <br/> Exchange</p></td>
      <td className="lrt-surface-color st-lrt"> <p className="table lrt-light-color "> Event <br/> Reserve</p></td>
      <td className="lrt-surface-color st-lrt"> <p className="table lrt-light-color "> Gift <br/> Cards</p></td>
  </tr>
</table>
          </div>
          </div>


        {Flow}
        {Images}
        <h1 className="pullquote l-w2 lrt-lighttext-color">
          The ticket checkout flow: favors clarity & flexibility, with ability to change seats or movie/showing. Food and drink selections occurs after checkout, as seats reservation is time sensitve.
        </h1>
        <div className="l-flexbox m-webMockup">{Images13}</div>
        <div className="l-flexbox m-webMockup">{Images35}</div>
        <h1 className="pullquote l-w2 lrt-lighttext-color">
          Gift cards: functionality was added, including purchasing gift cards, checking
          balance, and the ability to apply multiple gift cards in checkout.
        </h1>
        <div className="l-flexbox m-webMockup">{Images57}</div>
        <div className="l-flexbox m-webMockup">{LRTapp1}</div>
        <div className="l-flexbox m-webMockup">{LRTapp2}</div>
        <div className="l-w0 m-mobileProjLink">
                <Link to="/felt">
                <button className="projectDetails no-background lrt-surface-color m-projbutton">
                  <span className="button-text lrt-light-color">Next Project</span> <span className="material-icons lrt-light-color">arrow_forward</span>
                </button>
                </Link>
              </div></div>
    </Aux>
  );
};

export default lrt;
