import React, { Component } from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ImageRef } from "../../Images/ImageRef";
import { connectData } from "../../../data/app_data";
import ImageWithDesc from "../../Images/ImageWithDesc";

class connect extends Component {
  render() {
    let flow = connectData.slice(0, 1).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let connectapp1 = connectData.slice(1, 2).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    return (
      <Aux>
        <Helmet>
          <title>BLT - Hyperconnect</title>
          <meta name="description" content="Voice UI Concept" />
          <html className="hyper-background-color"/>
        </Helmet>
        <div className="hyper-background-color">
        <div className="l-w1 l-flexbox-alldevs projTitle">
        <div className="l-w1 m-projCopy animated">
          <h1 className="m-quote topper hyper-lighttext-color">
          Hyper Connect
          </h1>
          </div>
          <div className="introList animated delay3">
          <li className="abcd hyper-border-color hyper-light-color">2022</li>
<li className="abcd hyper-border-color hyper-light-color">Concept</li>
            <li className="abcd hyper-border-color hyper-light-color">E-Commerce</li>
            <li className="abcd hyper-border-color hyper-light-color">UX UI</li>
              </div>
            </div>

          <div className="l-w1 m-projCopy animated delay">
            <div className="pad-Bot-60">
              {/*<h2 className="m-projTitleGroup hyper-lighttext-color">Challenge</h2>*/}
              <p className="m-projCopy-top hyper-lighttext-color">
 Google's interview take-home assignment to design an infotainment experience for a next-gen commuter railway company. This mobile infotainment experience includes the ability to adjust the cabin experience (lighting and temperature), request service items (pillow, headphones, beverages), and stay informed about their journey.
              </p>
            </div>
          </div>



          <div className="hidemobile">
          														<table class="pad-Bot-60 l-w1 animated delay ">
          									<tr>
          											<th className="hyper-surface-color st-hyper principles">
          											<p className="m-projCopy hyper-lighttext-color">Accessibility x Beauty</p> <br/>
          																										<p className="m-projCopy hyper-lighttext-color">
          																											 This intersection involves creating designs that are both aesthetically pleasing and accessible to all users equally. While beauty is subjective, incorporating inclusive design principles that can benefit all users, which can lead to greater user satisfaction and loyalty.
          																										</p></th>
          											<th className="hyper-surface-color st-hyper principles">
          											<p className="m-projCopy hyper-lighttext-color">Clarity</p> <br/>
          																									<p className="m-projCopy hyper-lighttext-color">
          																										This ensures a user can easily understand the information that is conveyed, and helps reduce user frustration and confusion. Visual clarity comes through using hierarchy, whitespace, contrast, design patterns, and legible typography. While UX clarity comes from intuitive navigation, with concise language.
          																									</p></th>
          									</tr>
          									<tr>
          											<td className="hyper-surface-color st-hyper principles">
          											<p className="m-projCopy hyper-lighttext-color">At a glance</p> <br/>
          																											<p className="m-projCopy hyper-lighttext-color">
          																												Especially for mobile, user experiences need to provide essential information quickly and efficiently. Users are often short on time, easily distracted, and expect intuitive and efficient interfaces. "At a glance" info helps  identify what is important / relevant, saves time and effort, and increases engagement and satisfaction.
          																											</p></td>
          											<td className="hyper-surface-color st-hyper principles">
          											<p className="m-projCopy hyper-lighttext-color">Premium feel</p> <br/>
          																											<p className="m-projCopy hyper-lighttext-color">
          																											This feeling comes from typography and imagery consistent with the brand's values, tone, and style as well as overall visual appeal. A Premium feel stems from an intuitive and easy to use experience, with snappy, seamless and smooth interactions and minimal friction.
          																											</p></td>
          									</tr>
          							</table>
          							</div>
          <div className="l-flexbox m-webMockup animated delay ">{flow}</div>
          <div className="l-flexbox m-webMockup animated delay ">{connectapp1}</div>
          <div className="l-w0 m-mobileProjLink">
          <Link to="/felt">
          <button className="projectDetails no-background hyper-surface-color m-projbutton">
            <span className="button-text hyper-light-color">Next Project</span> <span className="material-icons hyper-light-color">arrow_forward</span>
          </button>
          </Link>
                </div></div>
      </Aux>
    );
  }
}

export default connect;
