import React from 'react';
import { Link } from 'react-router-dom';

const homeContent = props => {
	const { item } = props;
	return (
		<div className="m-landinglist">
			<Link to={item.link}>
				<button className="no-background home-hover m-homebutton">
					<span className="home-light-color">{item.description}</span>
				</button>
			</Link>
		</div>
	);
};

export default homeContent;
