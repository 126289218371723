import React from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { gatoradeData } from "../../../data/app_data";
import { ImageRef } from "../../Images/ImageRef";
import ImageWithDesc from "../../Images/ImageWithDesc";


const gatorade = props => {
	let Images13 = gatoradeData.slice(0, 2).map((item, index) => {
		return <ImageWithDesc item={item} key={index} />;
	});
	let Images35 = gatoradeData.slice(2, 4).map((item, index) => {
		return <ImageWithDesc item={item} key={index} />;
	});
	let Images57 = gatoradeData.slice(4, 6).map((item, index) => {
		return <ImageWithDesc item={item} key={index} />;
	});
	let Images7 = gatoradeData.slice(6, 7).map((item, index) => {
		return <ImageWithDesc item={item} key={index} />;
	});
	let Images8 = gatoradeData.slice(7, 8).map((item, index) => {
		return <ImageWithDesc item={item} key={index} />;
	});

	return (
		<Aux>
			<Helmet>
				<title>BLT - Gatorade</title>
				<meta name="description" content="Gatorade Data Collection App" />
				<html className="gatorade-background-color"/>
			</Helmet>
			<div className="gatorade-background-color">

<div className="l-w1 m-projCopy animated">
				<h1 className="m-quote topper gatorade-lighttext-color">Gatorade<br/>research
				</h1>
				</div>
				<div className="l-w1 l-flexbox-alldevs projTitle">
				<div className="introList animated delay3">
<li className="abcd gatorade-surface-color gatorade-light-color">2020</li>
<li className="abcd gatorade-surface-color gatorade-light-color hidemobile">1 month</li>
					<li className="abcd gatorade-surface-color gatorade-light-color">Sports science</li>
					<li className="abcd gatorade-surface-color gatorade-light-color">UX UI</li>

						</div>
					</div>
				<div className="l-flexbox-grey animated delay l-w1">
				<div className="m-twoColumnCopyA pad-Bot-60">
				{/*<h2 className="m-projTitleGroup gatorade-lighttext-color">User Research</h2>*/}
									<p className="m-projCopy-top gatorade-lighttext-color">
Gatorade scientists regularly tested athletes simultaneously and collected data asynchronously using pen and paper. <br/><br/> Transpated to tablet, this meant using common patterns, little to no nesting, large affordances, autofill suggestions, flexible navigation, easy commenting, and integrated report generation. </p>
								</div>
</div>
<div className="hidemobile">
														<table class="pad-Bot-60 l-w1 animated delay ">
									<tr>
											<th className="gatorade-surface-color st-gatorade principles">
											<p className="m-projCopy gatorade-lighttext-color">Mental Model</p> <br/>
																										<p className="m-projCopy gatorade-lighttext-color">
																											Navigational hints and digital wayfinding helps users understand their place within the system
																										</p></th>
											<th className="gatorade-surface-color st-gatorade principles">
											<p className="m-projCopy gatorade-lighttext-color">Fitts Law</p> <br/>
																									<p className="m-projCopy gatorade-lighttext-color">
																										Access to a click target is faster when the target is larger and more accessible
																									</p></th>
									</tr>
									<tr>
											<td className="gatorade-surface-color st-gatorade principles">
											<p className="m-projCopy gatorade-lighttext-color">Recognition</p> <br/>
																											<p className="m-projCopy gatorade-lighttext-color">
																												Symbols are interpreted many times faster than reading text, and even faster when it is familiar
																											</p></td>
											<td className="gatorade-surface-color st-gatorade principles">
											<p className="m-projCopy gatorade-lighttext-color">Patterns</p> <br/>
																											<p className="m-projCopy gatorade-lighttext-color">
																											Common patterns offer learnability, while learning a new way of doing things is difficult and takes time
																											</p></td>
									</tr>
							</table>
							</div>


{/*
<section className="l-w1 pad-Bot-60">
	<h2 className=" m-projTitleGroup gatorade-lighttext-color">User Flows</h2>
	<div className="l-w1 pad-Bot-60">

														<FlowDiagram data={gatoradeTesting} />

														</div>
														</section>
*/}

				<h4 className="l-w1 m-projTitle small center"></h4>
<div className="l-flexbox animated delay m-webMockup">{Images8}</div>
<div className="l-flexbox m-webMockup">{Images13}</div>
				<div className="l-flexbox m-webMockup">{Images35}</div>
				<div className="l-flexbox m-webMockup">{Images57}</div>
				<div className="l-flexbox m-webMockup">{Images7}</div>

				<div className="l-w0 m-mobileProjLink">
								<Link to="/lrt">
								<button className="projectDetails no-background gatorade-surface-color m-projbutton">
									<span className="button-text  gatorade-light-color">Next Project</span> <span className="material-icons gatorade-light-color">arrow_forward</span>
								</button>
								</Link>
							</div></div>
		</Aux>
	);
};

export default gatorade;
