import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const initialState = {
  TextColorClassName: 'home-lighttext-color'}

const ImageWithDesc = props => {
	const { item } = props;
  const [classes, setClasses] = useState(initialState);
  const { pathname } = useLocation();


  const getClassnames = (pathname) => {
    switch (pathname) {
      case '/': return setClasses({ TextColorClassName: 'home-lighttext-color designFigure center'});
      case '/pixel': return setClasses({ TextColorClassName: 'android-lighttext-color designFigure center'});
      case '/ebay': return setClasses({ TextColorClassName: 'ebay-lighttext-color designFigure center'});
      case '/gatorade': return setClasses({ TextColorClassName: 'gatorade-lighttext-color designFigure center'});
      case '/lrt': return setClasses({ TextColorClassName: 'lrt-lighttext-color designFigure center'});
      case '/dentalportal': return setClasses({ TextColorClassName: 'dental-lighttext-color designFigure center'});
      case '/casestudy': return setClasses({ TextColorClassName: 'dental-lighttext-color designFigure center'});
      case '/felt': return setClasses({ TextColorClassName: 'felt-lighttext-color designFigure center'});
      case '/finplan': return setClasses({ TextColorClassName: 'finplan-lighttext-color designFigure center'});
      case '/shoe': return setClasses({ TextColorClassName: 'shoe-lighttext-color designFigure center'});
      case '/hyperconnect': return setClasses({ TextColorClassName: 'hyper-lighttext-color designFigure center'});
      case '/about': return setClasses({ TextColorClassName: 'home-lighttext-color designFigure center'});
      default: return classes;
    }
  }
  useEffect(() => {
    getClassnames(pathname.toLowerCase());
  }, [pathname])

	return (
		<div ng-repeat="image in store.images.slice(0, 1)">
			<div className={item.class}>
				<img
					className={item.border}
					src={require(`../../assets/${item.imagesrc}`)?.default} width="100%" alt={item.alt} />
				<figure className={classes.TextColorClassName}> {item.descriptionTitle} </figure>
			</div>
		</div>
	);
};

export default ImageWithDesc;
