const contents = [
  {
      name: "E-commerce (UX)",
      class: "",
      description: "Google Pixel ↗",
      longDescription: "",
      height: " ",
      link: "/Pixel",
      imagesrc: "images/home/ebay-home-1.jpg",
      alt: "eBay",
      buttonTitle: "See Role"
    },
    {
      name: "SAAS (UX)",
      class: "",
      description: "Delta Dental ↗",
      longDescription: "",
      height: " ",
      link: "/dentalPortal",
      imagesrc: "images/home/dental-home-1.jpg",
      alt: "Dental Portal",
      buttonTitle: "See Project"
    },
{
    name: "E-commerce (UX)",
    class: "",
    description: "eBay selling ↗",
    longDescription: "",
    height: " ",
    link: "/Ebay",
    imagesrc: "images/home/ebay-home-1.jpg",
    alt: "eBay",
    buttonTitle: "See Role"
  },
  {
    name: "Internal Tools (UX)",
    class: "",
    description: "Gatorade research ↗",
    longDescription: "",
    height: " ",
    link: "/gatorade",
    imagesrc: "images/home/gatorade-home-1.jpg",
    alt: "Gatorade",
    buttonTitle: "See Project"
  },
  {
    name: "Ecommerce (UX)",
    class: "",
    description: "LR Theaters ↗",
    longDescription: "",
    height: " ",
    link: "/lrt",
    imagesrc: "images/home/lrt-home-1.png",
    alt: "Living Room Theaters",
    buttonTitle: "See Project"
  },
  {
    name: "Ecommerce (UX)",
    class: " ",
    description: "Felt concept ↗",
    longDescription: "",
    height: " ",
    link: "/felt",
    imagesrc: "images/home/felt-home-1.png",
    alt: "Felt",
    buttonTitle: "See Concept"
  },
  {
    name: "SAAS (UX)",
    class: " ",
    description: "Voice UI ↗",
    longDescription: "",
    height: " ",
    link: "/shoe",
    imagesrc: "images/home/finplan-home-1.jpg",
    alt: "Research",
    buttonTitle: "See Concept"
  }
];

export default contents;
