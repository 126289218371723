import React, { Component } from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ImageRef } from "../../Images/ImageRef";
import { shoeData } from "../../../data/app_data";
import ImageWithDesc from "../../Images/ImageWithDesc";

class shoe extends Component {
  render() {
    let shoesapp1 = shoeData.slice(0, 1).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let shoesapp2 = shoeData.slice(1, 2).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let shoesapp3 = shoeData.slice(2, 3).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let shoesapp4 = shoeData.slice(3, 4).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let flow = shoeData.slice(4, 5).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    return (
      <Aux>
        <Helmet>
          <title>BLT - Sportswear VUI</title>
          <meta name="description" content="Voice UI Concept" />
          <html className="shoe-background-color"/>
        </Helmet>
        <div className="shoe-background-color">
          <div className="l-w1 m-projCopy animated">
          <h1 className="m-quote topper shoe-lighttext-color">
         Voice UI
          </h1>
          </div>
          <div className="l-w1 l-flexbox-alldevs projTitle">
    <div className="introList animated">
          <li className="abcd shoe-surface-color shoe-light-color">2020</li>
          <li className="abcd shoe-surface-color shoe-light-color">Concept</li>
            <li className="abcd shoe-surface-color shoe-light-color">E-Commerce</li>
            <li className="abcd shoe-surface-color shoe-light-color hidemobile">UX UI</li>
              </div>
              </div>
            

          <div className="l-w1 m-projCopy animated delay">
            <div className="pad-Bot-60">
              {/*<h2 className="m-projTitleGroup shoe-lighttext-color">Challenge</h2>*/}
              <p className="m-projCopy-top shoe-lighttext-color">
                An exploration of VUI, developing strategies to supplement limited provided visual affordances through copy writing and a clear framework of interaction. <br/> <br/>The outcome is a rethink of a typ shopping flow, asking focused questions paired with visual cues to convey system status, with intent that to be navigated by voice control and displayed on smart home units.
              </p>
            </div>
          </div>
<div className="l-flexbox m-webMockup animated delay ">{flow}</div>
          <div className="l-flexbox m-webMockup animated delay ">{shoesapp1}{shoesapp2}</div>
          <div className="l-flexbox m-webMockup animated delay ">{shoesapp3}{shoesapp4}</div>
          <div className="l-w0 m-mobileProjLink">
          <Link to="/about">
          <button className="projectDetails no-background shoe-surface-color m-projbutton">
            <span className="button-text shoe-light-color">About / Bio</span> <span className="material-icons shoe-light-color">arrow_forward</span>
          </button>
          </Link>
                </div></div>
      </Aux>
    );
  }
}

export default shoe;
