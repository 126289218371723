export const costcalcImages = [
{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/dental---3a.jpg",
    descriptionTitle: "Cost Calculator Landing Page",
    description: "",
    alt: "Cost Calculator Landing Page"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/dental---3b.jpg",
    descriptionTitle: "Cost Calculator Follow-up",
    description: "",
    alt: "Cost Calculator Results Page"
},
{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/dental---3c.jpg",
    descriptionTitle: "Cost Calculator Follow-up",
    description: "",
    alt: "Cost Calculator Landing Page"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/dental---3d.jpg",
    descriptionTitle: "Cost Calculator Results Page",
    description: "",
    alt: "Cost Calculator Results Page"

},{
    class: "m-twoColumnImageA",
    imagesrc: "images/web/casestudyapp---1.jpg",
    descriptionTitle: "Cost Calculator - Mobile",
    alt: "Cost Calculator - Mobile"
  },{
    class: "m-twoColumnImageB",
    imagesrc: "images/web/casestudyapp---2.jpg",
    descriptionTitle: "Cost Calculator - Mobile",
    alt: "Cost Calculator - Mobile"
  },{
      class: "",
      imagesrc: "images/web/casestudy---userflow.jpg",
      descriptionTitle: "Case Study Userflow",
      alt: "Case Study Userflow"
},{
    class: "",
    imagesrc: "images/web/casestudy---diagram.jpg",
    descriptionTitle: "Unused Diagrams",
    alt: "Diagram Study"
}
];


export const shoeData = [{
    class: " m-twoColumnImageA",
    descriptionTitle: "Shoe selection",
    imagesrc: "images/web/shoe---1.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Add to cart",
    imagesrc: "images/web/shoe---2.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Payment method",
    imagesrc: "images/web/shoe---3.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Order confirmation",
    imagesrc: "images/web/shoe---4.jpg",
    alt: ""
}, {
    class: "webMockup",
    descriptionTitle: "",
    imagesrc: "images/web/shoe---userflow.png",
    alt: ""
}
];

// Finplan Data
export const finplanData = [{
    class: "m-webMockup solo",
    descriptionTitle: "",
    imagesrc: "images/web/finplan---7.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Your Income",
    imagesrc: "images/web/finplan---1.jpg",
    alt: "Your Income"
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Your Assets",
    imagesrc: "images/web/finplan---2.jpg",
    alt: "Your Assets"
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Your Liabilities",
    imagesrc: "images/web/finplan---3.jpg",
    alt: "Your Liabilities"
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Goals Dashboard",
    imagesrc: "images/web/finplan---4.jpg",
    alt: "Goals Dashboard"
}, {
    class: "l-w1",
    description: "",
    imagesrc: "images/web/sitemap_finplan.png",
    alt: ""
}, {
    class: "l-w1",
    descriptionTitle: "Provisional Personas",
    border: "process_content m-t-60",
    imagesrc: "images/web/persona_finplan.jpg",
    alt: ""
}, {
    class: "process_content",
    border: "",
    imagesrc: "images/web/features_finplan.jpg",
    descriptionTitle: "",
    alt: ""
}, {
    class: "process_content",
    border: "",
    imagesrc: "images/web/problemstatement_finplan.jpg",
    descriptionTitle: "",
    alt: ""
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Welcome Sequence",
    imagesrc: "images/web/finplanapp---1.jpg",
    alt: "Edit Income/Assets"
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Edit Income/Assets",
    imagesrc: "images/web/finplanapp---2.jpg",
    alt: "Liabilities/Goals"
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Liabilities/Goals",
    imagesrc: "images/web/finplanapp---3.jpg",
    alt: "Your Goals / Edit"
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Your Goals / Edit",
    imagesrc: "images/web/finplanapp---4.jpg",
    alt: "Welcome Sequence"
}, ];


export const dashboardTasks = [{
    text: "color story"
}, {
    text: "styleguide"
}, {
    text: "inspiration imagery"
}, {
    text: "web mocks"
}];

export const dashboardData = [{
    class: "m-webMockup",
    white: "white",
    imagesrc: "images/web/delta---1.jpg",
    alt: "Murata Wood",
    descriptionTitle: "",
    description: "",
    quote: ""
}, {
    class: " m-twoColumnImageA",
    imagesrc: "images/web/delta---2.jpg",
    alt: "Delta Dashboard 1"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/delta---3.jpg",
    alt: "Delta Dashboard 2"
}];


export const feltTasks = [{
    text: "color story"
}, {
    text: "styleguide"
}, {
    text: "inspiration imagery"
}, {
    text: "web mocks"
}];

export const feltData = [{
    class: "m-webMockup solo",
    imagesrc: "images/web/felt---7.jpg",
    alt: "Felt - Laptop/Mobile",
    descriptionTitle: "Responsiveness - Homepage",
    description: "",
    quote: "These mock-ups present a unified style for desktop and mobile, reducting text on category pages and simplified navigation. Product information is solely communicated on product pages, with imagery driving navigation."
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/felt---1.jpg",
    alt: "Felt",
    quote: "For category pages, snippets of text were removed from each card, allowing the design of the bikes to be celebrated, and for the interface to be cleaner and further simplified.",
    descriptionTitle: "Product Search"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/felt---2.jpg",
    alt: "Felt",
    descriptionTitle: "Product Page"
}, {
    class: " m-twoColumnImageA",
    imagesrc: "images/web/felt---3.jpg",
    alt: "Felt",
    descriptionTitle: "Checkout 1"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/felt---4.jpg",
    alt: "Felt",
    descriptionTitle: "Checkout 2"
}, {
    class: " m-twoColumnImageA",
    imagesrc: "images/web/felt---5.jpg",
    alt: "Felt",
    descriptionTitle: "Order Confirmation"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/felt---6.jpg",
    alt: "Felt",
    descriptionTitle: "Sign In"
}, {
    class: "styleguide",
    border: "",
    imagesrc: "images/web/felt---font.jpg",
    alt: "Felt Fonts",
    descriptionTitle: "",
    quote: "The klavika font was chosen based on its lightweight nature and its versatility of font treatments - with an edgy nature which pairs well with the competitiveness of road racing."
}, {
    class: "l-w1",
    imagesrc: "images/web/sitemap_felt.png",
    alt: "Location Page"
}, {
    class: "l-w1",
    imagesrc: "images/web/persona_felt.jpg",
    alt: "Location Page",
    border: "process_content m-t-60",
    descriptionTitle: "Provisional Personas"
}, {
    class: "process_content",
    border: "",
    imagesrc: "images/web/features_felt.jpg",
    descriptionTitle: "",
    description: "",
    alt: "Illustration 4"
}, {
    class: "process_content",
    border: "",
    imagesrc: "images/web/problemstatement_felt.jpg",
    descriptionTitle: "",
    description: "",
    alt: "Illustration 4"
}, {
    class: "l-w1 process_content",
    border: "",
    imagesrc: "images/web/styleguide_felt.jpg",
    descriptionTitle: "",
    alt: "Illustration 4"
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Landing & Product Search",
    imagesrc: "images/web/feltapp---1.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Checkout Flow 1",
    imagesrc: "images/web/feltapp---2.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Checkout Flow 2",
    imagesrc: "images/web/feltapp---3.jpg",
    alt: ""
}, {
    class: " m-twoColumnImageB",
    descriptionTitle: "Sign In & Order Details",
    imagesrc: "images/web/feltapp---4.jpg",
    alt: ""
}, {
    class: "m-webMockup",
    border: "",
    imagesrc: "images/web/felt---flow.jpg",
    descriptionTitle: "",
    description: "User Flows",
    alt: "Illustration 4"
}];

// LRT data

export const lrtTasks = [{
    text: "Complete Redesign"
}, {
    text: "Styleguide"
}, {
    text: "Iconography"
}, {
    text: "User FLow"
}];
export const lrtData = [{
    class: "m-webMockup solo",
    imagesrc: "images/web/lrt---7.jpg",
    descriptionTitle: "Responsiveness - Homepage",
    alt: "Fall"
}, {
    class: " m-twoColumnImageA",
    imagesrc: "images/web/lrt---1a.jpg",
    descriptionTitle: "Movie Details",
    description: "",
    alt: "Product Page"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/lrt---1b.jpg",
    descriptionTitle: "Seat Selection",
    alt: "Product Page"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/lrt---2a.jpg",
    descriptionTitle: "Order Checkout",
    description: "",
    alt: "Fashion Sketch"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/lrt---2b.jpg",
    descriptionTitle: "Order Checkout Complete",
    description: "",
    alt: "Fashion Sketch"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/lrt---3b.jpg",
    descriptionTitle: "Payment Cards",
    description: "",
    alt: "Fashion Sketch"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/lrt---4b.jpg",
    descriptionTitle: "Order Credited to Gift Card",
    description: "",
    alt: "Fashion Sketch"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/lrtapp---1.jpg",
    descriptionTitle: "Movie Selection",
    description: "",
    alt: "Illustration 4"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/lrtapp---2.jpg",
    descriptionTitle: "Checkout Process",
    description: "",
    alt: "Illustration 4"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/lrtapp---3.jpg",
    descriptionTitle: "Ancillary",
    description: "",
    alt: "Illustration 4"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/lrtapp---4.jpg",
    descriptionTitle: "Preorder Food",
    description: "",
    alt: "Illustration 4"
}, {
    class: "m-webMockup",
    border: "",
    imagesrc: "images/web/lrt---flow.jpg",
    descriptionTitle: "",
    description: "User Flows",
    alt: "Illustration 4"
}];

// dental optimizer data
export const dentalTasks = [{
    text: "Complete Redesign"
}, {
    text: "Styleguide"
}, {
    text: "Iconography"
}, {
    text: "User FLow"
}];
export const dentalImages = [{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/dental---1a.jpg",
    descriptionTitle: "Dentist Search Results",
    description: "",
    alt: "Dentist Search Results"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/dental---1b.jpg",
    descriptionTitle: "Dentist Profile Page",
    alt: "Dentist Profile Page"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/dental---2a.jpg",
    descriptionTitle: "Patient Dashboard",
    description: "",
    alt: "Patient Dashboard"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/dental---2b.jpg",
    descriptionTitle: "Patient Dashboard (Scroll)",
    description: "",
    alt: "Patient Dashboard (Scroll)"
}, {
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/dental---3a.jpg",
    descriptionTitle: "Cost Calculator Landing Page",
    description: "",
    alt: "Cost Calculator Landing Page"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/dental---3b.jpg",
    descriptionTitle: "Cost Calculator Results Page",
    description: "",
    alt: "Cost Calculator Results Page"
},
{
    class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/dental---3c.jpg",
    descriptionTitle: "Cost Calculator Landing Page",
    description: "",
    alt: "Cost Calculator Landing Page"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/dental---3d.jpg",
    descriptionTitle: "Cost Calculator Results Page",
    description: "",
    alt: "Cost Calculator Results Page"

}, {
  class: " m-twoColumnImageA",
    border: "",
    imagesrc: "images/web/dental---4a.jpg",
    descriptionTitle: "Dentist Onboarding",
    description: "",
    alt: "Dentist Onboarding"
}, {
    class: " m-twoColumnImageB",
    border: "",
    imagesrc: "images/web/dental---4b.jpg",
    descriptionTitle: "Dentist Onboarding",
    description: "",
    alt: "Dentist Onboarding"
},{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/dentalapp---1.jpg",
    descriptionTitle: "Landing/Login - Mobile",
    alt: "Landing/Login - Mobile"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/dentalapp---2.jpg",
    descriptionTitle: "Dentist Finder - Mobile",
    alt: "Dentist Finder - Mobile"
}, {
    class: " m-twoColumnImageA",
    imagesrc: "images/web/dentalapp---3.jpg",
    descriptionTitle: "Cost Calculator - Mobile",
    alt: "Cost Calculator - Mobile"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/dentalapp---4.jpg",
    descriptionTitle: "Ask A Dentist - Mobile",
    alt: "Ask A Dentist - Mobile"
  }, {
      class: "m-webMockup",
      imagesrc: "images/web/dental---7.jpg",
      descriptionTitle: "Dentist Search Results",
      description: "",
      alt: "Dentist Search Results"
},{
    class: "",
    imagesrc: "images/web/dental---finder.jpg",
    descriptionTitle: "",
    alt: "Dentist Finder - User Flow"
},{
    class: "m-webMockup",
    imagesrc: "images/web/dental---landing.jpg",
    descriptionTitle: "",
    alt: "Landing Page - User Flow"
},{
    class: "",
    imagesrc: "images/web/dental---benefits.jpg",
    descriptionTitle: "",
    alt: "Landing Page - User Flow"
},{
    class: "",
    imagesrc: "images/web/dental---finder.jpg",
    descriptionTitle: "",
    alt: "Landing Page - User Flow"
},{
    class: "",
    imagesrc: "images/web/dental---roadmap.jpg",
    descriptionTitle: "",
    alt: "Dental Roadmap"
}];

export const gatoradeData = [{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/gatorade---1.jpg",
    descriptionTitle: "Select a Team",
    alt: "Select a Team (or Individual)"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/gatorade---2.jpg",
    descriptionTitle: "Athlete Background Form",
    alt: "Athlete Background Form"
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "Pre Test Form",
    imagesrc: "images/web/gatorade---3.jpg",
    alt: "Pre Test Form"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/gatorade---4.jpg",
    alt: "Mid Test Form",
    descriptionTitle: "Mid Test Form"
}, {
    class: " m-twoColumnImageA",
    descriptionTitle: "End of Test Form",
    imagesrc: "images/web/gatorade---5.jpg",
    alt: "End of Test Form"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/gatorade---6.jpg",
    alt: "Post Test Form",
    descriptionTitle: "Post Test Form"
  }, {
      class: "webMockup",
      imagesrc: "images/web/gatorade---7.jpg",
      alt: "Review & Generate Report",
      descriptionTitle: "Review & Generate Report"
  }, {
        class: "webMockup",
        imagesrc: "images/web/gatorade---userflow.jpg",
        descriptionTitle: "User Flow",
        alt: "User Flow"
}];


export const connectData = [{
    class: " m-twoColumnImageA",
    imagesrc: "images/web/hyperconnect---userflow.jpg",
    descriptionTitle: "Infotainment user flow",
    alt: "Select a Team (or Individual)"
}, {
    class: " m-twoColumnImageB",
    imagesrc: "images/web/hyperconnect---1.jpg",
    descriptionTitle: "Infotainment sketches",
    alt: "Athlete Background Form"
},];

// ebay Data

export const ebayData = [{
  class: "",
   imagesrc: "images/web/ebay---comp1.jpg",
   descriptionTitle: "Onboarding  comparison",
   alt: "Analysis - onboarding"
},{
  class: "",
   imagesrc: "images/web/ebay---comp2.jpg",
   descriptionTitle: "eBay Onboarding",
   alt: "eBay Onboarding I"
},{
  class: "",
   imagesrc: "images/web/ebay---comp3.jpg",
   descriptionTitle: "eBay Onboarding",
   alt: "eBay Onboarding II"
},{
  class: "",
   imagesrc: "images/web/ebay---sub1.jpg",
   descriptionTitle: "Subscribe to a store",
   alt: "Carriage House"
},{
  class: "",
   imagesrc: "images/web/ebay---sub2.jpg",
   descriptionTitle: "Subscribe to a store",
   alt: "Subscribe to a store - 2"
},{
  class: "",
   imagesrc: "images/web/ebay---sub3.jpg",
   descriptionTitle: "Subscribe to a store",
   alt: "Subscribe to a store - 3"
},{
  class: "",
   imagesrc: "images/web/ebay---tera1.jpg",
   descriptionTitle: "Subscribe to Terapeak",
   alt: "Subscribe to Terapeak - 1"
},{
  class: "",
   imagesrc: "images/web/ebay---tera2.jpg",
   descriptionTitle: "Subscribe to Terapeak",
   alt: "Subscribe to Terapeak - 2"
},{
  class: "",
     imagesrc: "images/web/ebay---previous.jpg",
     descriptionTitle: "Previous",
     alt: "Carriage House"
 },{
   class: "",
    imagesrc: "images/web/ebay---updated.jpg",
    descriptionTitle: "Updated Experience",
    alt: "Carriage House"
},{
  class: "",
   imagesrc: "images/web/ebay---seams.jpg",
   descriptionTitle: "Seams",
   alt: "Carriage House"
}];








