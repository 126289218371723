import React from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { feltData } from "../../../data/app_data";
import { ImageRef } from "../../Images/ImageRef";
import ImageWithDesc from "../../Images/ImageWithDesc";

const felt = props => {
  let Images = feltData.slice(0, 1).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images13 = feltData.slice(1, 3).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images35 = feltData.slice(3, 5).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images57 = feltData.slice(5, 7).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let Images89 = feltData.slice(8, 9).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let Images10 = feltData.slice(9, 10).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let featureList = feltData.slice(10, 11).map((item, index) => {
    return <ImageRef item={item} key={index} />;
  });
  let problemStatement = feltData.slice(11, 12).map((item, index) => {
    return <ImageRef item={item} key={index} />;
  });
  let styleguide = feltData.slice(12, 13).map((item, index) => {
    return <ImageRef item={item} key={index} />;
  });
  let app1 = feltData.slice(13, 15).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let app2 = feltData.slice(15, 17).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let Flow = feltData.slice(17, 18).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  return (
    <Aux>
      <Helmet>
        <title>BLT - Felt Bicycles Website Design</title>
        <meta name="description" content="Felt Bicycles Website Design" />
        <html className="felt-background-color"/>
      </Helmet>
      <div className="felt-background-color">
      <div className="l-w1 l-flexbox-alldevs projTitle">
        <div className="introList animated delay3">
        <div className="m-projCopy animated">
        <h1 className="m-quote topper felt-lighttext-color">Felt bikes
        </h1>
        </div>
        <div className="introList animated delay3">
          <li className=" abcd felt-surface-color felt-light-color">2019</li>
          <li className="abcd felt-surface-color felt-light-color">Concept</li>
          <li className=" abcd felt-surface-color felt-light-color">ECommerce</li>
          <li className="abcd felt-surface-color felt-light-color hidemobile">UX UI</li>
            </div>
          </div>
          </div>
        <div className="l-flexbox m-projCopy animated delay l-w1">
          <div className="m-twoColumnCopyA pad-Bot-60">
            {/*<h2 className="m-projTitleGroup felt-lighttext-color">Scope</h2>*/}
            <p className="m-projCopy-top felt-lighttext-color">
Felt's site included discontinuous device experiences, wordy writing, and dated patterns. Goal of this concept site to introduce consistency across devices, a new design system, simplified overall pages and concise writing throughout.</p>
          </div>

        </div>
        {/*
        <FlowDiagram data={feltCheckOut} />
        <FlowDiagram data={feltOrders} />
        <FlowDiagram data={feltWarranty} />
        */}



      {/*  <div className="l-w1 animated delay2">
          <h4 className="m-projTitle small center">User Research</h4>
          <p className="m-projCopy l-2cl">
          Biking isn't for kids anymore: according to the Gluskin Townley Group,
          the number of children who ride bicycles declined more than 20%
          between 2000 and 2010. As well, enthusiasts are driving growth: the
          number of frequent cyclists increased 12 percent over the decade, with
          adult male enthusiast riders increasing even more, by 15%. Male adult
          infrequents have risen 21% during this same time period. High growth
          in age groups of 25-34, as well as 35-44. According to Consumer
          Behavior, consumers are buying bikes for not only for efficiency
          reasoning, but for personal style, status, and environmental reasons.
          This site was designed more towards adult enthusiast riders between
          ages 25-44. According to Consumer Behavior, personal style, status,
          and the environment are primary considerations for this consumer
          segment, and these mockups place special focus on quality and style of
          Felt's cutting-edge bike lineup.
        </p>
        </div>
       {Images10}
       */}
        <div className="l-w1 pad-Bot-60 animated delay ">
            <table>
  <tr>
      <th className="felt-surface-color st-felt"> <p className="table felt-light-color "> Order View</p> </th>
      <th className="felt-surface-color st-felt"> <p className="table felt-light-color "> Item View</p> </th>
      <th className="felt-surface-color st-felt"> <p className="table felt-light-color "> Browse </p> </th>
  </tr>
  <tr>
<td className="felt-surface-color st-felt"> <p className="table felt-light-color "> Search </p> </td>
      <td className="felt-surface-color st-felt"> <p className="table felt-light-color "> Checkout</p> </td>
      <td className="felt-surface-color st-felt"> <p className="table felt-light-color "> Purchases</p> </td>
  </tr>
</table>
          </div>
        {/*//<h4 className="l-w1 m-projTitle small center">Site Map</h4>
        {Images89} */}
        <h4 className="l-w1 m-projTitle small center"></h4>
        {Flow}
        {Images}
        <div className="l-flexbox m-webMockup">{Images13}</div>
        <div className="l-flexbox m-webMockup">{Images35}</div>
        <div className="l-flexbox m-webMockup">{Images57}</div>
        <div className="l-flexbox m-webMockup">{app1}</div>
        <div className="l-flexbox m-webMockup">{app2}</div>
        <div className="l-w0 m-mobileProjLink">
                <Link to="/shoe">
                <button className="projectDetails no-background felt-surface-color m-projbutton">
                  <span className="button-text felt-light-color">Next Project</span> <span className="material-icons felt-light-color">arrow_forward</span>
                </button>
                </Link>
              </div></div>
    </Aux>
  );
};

export default felt;
