// dss = "Dan's Special Secret"
const dssHex = "706978656C";

function hex_to_ascii(str1) {
  let hex = str1.toString();
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

const dss = hex_to_ascii(dssHex)

export const dss_Arr = dss.split('');


