import React from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { finplanData } from "../../../data/app_data";
import { ImageRef } from "../../Images/ImageRef";
import ImageWithDesc from "../../Images/ImageWithDesc";

const finplan = props => {
  let ImageRef = finplanData.slice(0, 1).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });

  let ImageRef13 = finplanData.slice(1, 3).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let ImageRef35 = finplanData.slice(3, 5).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let FinplanAPP1 = finplanData.slice(9, 11).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let FinplanAPP2 = finplanData.slice(11, 13).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let ImageRef56 = finplanData.slice(5, 6).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let personas = finplanData.slice(6, 7).map((item, index) => {
    return <ImageWithDesc item={item} key={index} />;
  });
  let featureList = finplanData.slice(7, 8).map((item, index) => {
    return <ImageRef item={item} key={index} />;
  });
  let problemStatement = finplanData.slice(8, 9).map((item, index) => {
    return <ImageRef item={item} key={index} />;
  });

  return (
    <Aux>
      <Helmet>
        <title>BLT - Finplan</title>
        <meta name="description" content="Sight-C Website Design" />
      </Helmet>
      <div className="finplan-background-color">
      <div className="l-w1 l-flexbox-alldevs projTitle">
        <div className="introList animated delay3">
        <li className="abcd finplan-surface-color finplan-light-color">2020</li>
          <li className="abcd finplan-surface-color finplan-light-color">Concept</li>
          <li className="abcd finplan-surface-color finplan-light-color">Finance</li>
          <li className="abcd finplan-surface-color finplan-light-color">UX UI</li>
            </div>
          </div>
        <div className="l-w1 m-projCopy animated">
        <h1 className="m-quote starter m-t-20 finplan-lighttext-color"> A design concept, Finplan, to track progress for financial goals & optimize investments with smart recommendations ↴
        </h1>
        </div>
        <div className="l-flexbox m-projCopy animated delay l-w1">
          <div className="m-twoColumnCopyA pad-Bot-60">
            <h2 className="m-projTitleGroup finplan-lighttext-color">Scope</h2>
            <p className="m-projCopy-top finplan-lighttext-color">
              Finplan is intended to be a technically-oriented yet intuitive
              financial plannig app. It is intended to automate and project out
              the performance of investments over time, to track how goals are
              being achieved, and optimize investments by making smart
              recommendations.
            </p>
          </div>
          <div className="m-twoColumnCopyB pad-Bot-60">
            <h2 className="m-projTitleGroup finplan-lighttext-color">Solution</h2>
            <p className="m-projCopy-top finplan-lighttext-color">
              The challenge with the layout of all of these features was to
              group similar tools together, while maintaining the heirarchy of
              the site.
            </p>
          </div>
        </div>
      {/*
      <div className="l-w1 animated delay2">
          <h4 className="m-projTitle small center">User Research</h4>
          <p className="m-projCopy l-2cl">
          This user research has been compiled from online sources. Most people tend to look for the least resistant route, even when it’s not always the best choice. We’re lead by our own instincts to satisfy the current desire instead of a distant goal far in the future, but this can be influenced by a sense of easier and better decision-making. Digital finance tools can be the rational aid that the irrational and emotional side of humans often lack. Users are in need of coaching and motivation to pursue long-term goals, even at the expense of losing sight of short-term goals. It can help people make better decisions and take up the financial responsibility.
        </p>
        </div>
        {personas}
        */}
        <div className="l-flexbox l-w1 pad-Bot-60">
          <div className="hideme m-twoColumnImageA">
            <h4 className="m-projTitle small center finplan-lighttext-color">Feature Set</h4>

            <table>
  <tr>
      <th className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">Dashboard</p></th>
      <th className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">User Prefs</p></th>
      <th className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">Advisors</p></th>
  </tr>
  <tr>
      <td className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">Track <br/> Goals</p></td>
      <td className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">Smart <br/> Tips</p></td>
      <td className="finplan-surface-color st-finplan"><p className="table finplan-light-color ">Shared <br/> Goals</p></td>
  </tr>
</table>
        </div></div>

        {/*<h4 className="l-w1 m-projTitle small center">User Flow</h4>
        {ImageRef56}
        */}
        <h4 className="l-w1 m-projTitle small center"></h4>
        {ImageRef}
        <div className="l-flexbox m-webMockup">{ImageRef13}</div>
        <div className="l-flexbox m-webMockup">{ImageRef35}</div>
        <div className="l-flexbox m-webMockup">{FinplanAPP1}</div>
        <div className="l-flexbox m-webMockup">{FinplanAPP2}</div>
<div className="l-w0 m-mobileProjLink">
        <Link to="/shoe">
        <button className="projectDetails no-background finplan-surface-color m-b-60 m-bt-60">
          <span className="button-text finplan-light-color">Next Project</span> <span className="material-icons finplan-light-color">arrow_forward</span>
        </button>
        </Link>
      </div></div>
    </Aux>
  );
};

export default finplan;
