import React, {lazy, Suspense} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// import PageTransition from 'react-router-page-transition';
import { dss_Arr } from "./data/pix_utilities";
import Home from "./containers/Home";
import Homelayout from "./hoc/Layout/HomeLayout";
import DentalPortal from "./components/design/DentalOptimzer/DentalPortal";
import CaseStudy from "./components/design/CaseStudy/CaseStudy";
import LRT from "./components/design/LRT/LRT";
import Finplan from "./components/design/Finplan/Finplan";
import Felt from "./components/design/Felt/Felt";
import Shoe from "./components/design/Shoe/Shoe";
import Gatorade from "./components/design/Gatorade/Gatorade";
import Ebay from "./components/design/Ebay/Ebay";
import Connect from "./components/design/hyperconnect/hyperconnect";
import About from "./components/About/About";
import Homeaux from "./hoc/_Aux/_Homeaux";

const PixelComponent =  lazy(() => import("./components/design/Pixel/Pixel"))

function App (props) {

  const [trusted, setTrusted] = React.useState(false)

  const [routes, setRoutes] = React.useState([])

React.useEffect(() => {  

  let index = 0
  const pix_Service_Provider = () => {
    window.addEventListener('keydown', keyHandler)    
  }

  const keyHandler = (evt) => {

    if (evt.key === dss_Arr[index] && index === dss_Arr.length-1) {
      window.removeEventListener('keydown', keyHandler)
      index = 0
      setTrusted(true)
    }
    else if (evt.key === dss_Arr[index]) {
      window.removeEventListener('keydown', keyHandler)
      index++
      return pix_Service_Provider(); 
    }
    else if (evt.key === dss_Arr[0]) {
      window.removeEventListener('keydown', keyHandler)
      index = 1; 
      return pix_Service_Provider(); 
    }
    else {
      index = 0; 
    }
  }

  pix_Service_Provider();

}, [])

    React.useEffect(() => {
      if(trusted) {
        setRoutes((prevRoutes) => {
          return [
            //TODO create loading component for use as fallback
                <Route exact path="/Pixel" component={() => <Suspense fallback={<div></div>}><PixelComponent/></Suspense>}/>,
              ...prevRoutes
            ]
          })
        
      }
    }, [trusted])  

    return (
      <Homeaux>
        <Homelayout>
          <Switch location={props.location} key={props.location.key}>
            {routes},
            <Route exact path="/dentalPortal" component={DentalPortal} />,
            <Route exact path="/caseStudy" component={CaseStudy} />,
            <Route exact path="/crypto" component={Crypto} />,
            <Route exact path="/lrt" component={LRT} />,
            <Route exact path="/finplan" component={Finplan} />,
            <Route exact path="/ebay" component={Ebay} />,
            <Route exact path="/hyperconnect" component={Connect} />,
            <Route exact path="/felt" component={Felt} />,
            <Route exact path="/about" component={About} />,
            <Route exact path="/shoe" component={Shoe} />,
            <Route exact path="/gatorade" component={Gatorade} />,
            <Route path="/">
              <Home trusted={trusted} />
            </Route>
            <Redirect to="/" />
          </Switch> 
        </Homelayout>
      </Homeaux>
    );
}

export default withRouter(App);
