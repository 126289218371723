import React, { Component } from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ImageRef } from "../../Images/ImageRef";
import  ImageWithDesc  from "../../Images/ImageWithDesc";
import { costcalcImages } from "../../../data/app_data";

class DentalOptimizer extends Component {
  render() {
    let Images02 = costcalcImages.slice(0, 2).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    //let Images1a = costcalcImages.slice(17, 18).map((item, index) => {
      //return <ImageRef item={item} key={index} />;
    //});
    let Images24 = costcalcImages.slice(2, 4).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let Images46 = costcalcImages.slice(4, 6).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let Images67 = costcalcImages.slice(6, 7).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let Images78 = costcalcImages.slice(7, 8).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    return (
      <Aux>
        <Helmet>
          <title>BLT - Dental</title>
          <meta name="description" content="Cost Calculator Case Study" />
          <html className="dental-background-color"/>
        </Helmet>
        <div className="dental-background-color">
          
  <div className="l-w1 l-flexbox-alldevs projTitle">
  <div className="l-w1 m-projCopy animated">
          <h1 className="m-quote topper dental-lighttext-color">
Cost Calc</h1>
          </div>
    <div className="introList animated">
          <li className="abcd dental-surface-color dental-light-color">2017</li>
      <li className="abcd dental-surface-color dental-light-color hidemobile">26 months</li>
      <li className="abcd dental-surface-color dental-light-color">Insurance</li>
      <li className="abcd dental-surface-color dental-light-color">UX UI QA</li>
      <li className="abcd dental-surface-color dental-light-color hidemobile">E2E</li>

        </div>
      </div>


<div className="l-w1 m-projCopy animated delay">
  <div className="pad-Bot-60">
              <p className="m-projCopy-top dental-lighttext-color">
              UXR had surfaced importance of the cost calculator matching those generated in-office. To land on a specific procedure code (CDT), a user selects an initial procedure category and answers follow-up questions. The final estimate pulls recent billings data and considers coverage specific aspects (deductibles, annual max, and coverages). <br/><br/>

              After building these user flows/ mocks and gaining approval, I prioritized tickets, with documentation of approach for these calculations, and a series of if/then statements to connect answers to CDT codes. As well, I polished this space, explored diagrams, and ran QA.
                </p>
              </div>
              </div>

          <div className="l-w1 animated delay ">
              <div className="feature_set pad-Bot-60">
              <table>
    <tr>
        <th className="dental-surface-color st-dental "> <p className="table dental-light-color ">Family Member</p></th>
        <th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Select Dentist </p></th>
        <th className="dental-surface-color st-dental"> <p className="table dental-light-color "> CDT Codes </p></th>
    </tr>
    <tr>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  ">Dentist Billings</p></td>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  "> Deductible</p></td>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  "> Annual Max </p></td>
    </tr>
</table>
</div>
            </div>
          <h4 className="l-w1 m-projTitle small center"></h4>
          <div className="l-flexbox m-webMockup">{Images67}</div>
          <div className="l-flexbox m-webMockup">{Images02}</div>
          <div className="l-flexbox m-webMockup">{Images24}</div>
            <div className="l-flexbox m-webMockup">{Images46}</div>
<div className="l-flexbox m-webMockup">{Images78}</div>

          <div className="l-w0 m-mobileProjLink">
                  <Link to="/dentalportal#section-three">
                  <button className="projectDetails no-background dental-surface-color m-b-60 m-bt-60">
                    <span className="button-text dental-light-color">Back to Dental</span> <span className="material-icons dental-light-color">arrow_forward</span>
                  </button>
                  </Link>

                </div></div>
      </Aux>
    );
  }
}

export default DentalOptimizer;
