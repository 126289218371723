import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Homeaux from "../hoc/_Aux/_Homeaux";
import data from "../data/app_home";
import HomeContent from "../components/Home/HomeContent";
class Home extends Component {
  state = {};
  render() {
    let content0 = data.slice(0, 1).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content1 = data.slice(1, 2).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content2 = data.slice(2, 3).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });

    let content3 = data.slice(3, 4).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content4 = data.slice(4, 5).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content5 = data.slice(5, 6).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content6 = data.slice(6, 7).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content7 = data.slice(7, 8).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    let content8 = data.slice(7, 8).map((item, index) => {
      return <HomeContent item={item} key={index} />;
    });
    
    return (
      <Homeaux>
        <Helmet>
          <title>BLT - Home</title>
          <meta
            name="description"
            content="Multi-disciplinary design work with a focus on visual communication and user experience design"
          />
        </Helmet>
        <div className="home-background-color introList">
            <div className="animated intro l-w1">
              <h2 className=" m-landingText home_topper animated delay3 home-light-color">UI designer <br/> based in pdx, working at Google</h2>
            </div>
            <div className="l-w1 animated delay2 p-90">
            {this.props.trusted ? content0 : undefined}
            {content1}
            {content2}
            {content3}
            {content4}
            {content5}
            {content6}
            {content7}
          </div>
        </div>
      </Homeaux>
    );
  }
}

export default Home;
