import React, { Component } from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ImageRef } from "../../Images/ImageRef";
import  ImageWithDesc  from "../../Images/ImageWithDesc";
import { dentalImages } from "../../../data/app_data";

class DentalOptimizer extends Component {
  render() {

    let Images24 = dentalImages.slice(0, 2).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });

    let Images46 = dentalImages.slice(2, 4).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });

    let Images68 = dentalImages.slice(4, 6).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let Images1012 = dentalImages.slice(8, 10).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let app1 = dentalImages.slice(10, 12).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let app2 = dentalImages.slice(12, 14).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let Images = dentalImages.slice(14, 15).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let FinderFlow = dentalImages.slice(15, 16).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let LandingFlow = dentalImages.slice(16, 17).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let BenefitsFlow = dentalImages.slice(17, 18).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let ClaimFlow = dentalImages.slice(18, 19).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });
    let RoadMap = dentalImages.slice(19, 20).map((item, index) => {
      return <ImageWithDesc item={item} key={index} />;
    });

    return (
      <Aux>
        <Helmet>
          <title>BLT - Dental</title>
          <meta name="description" content="Dental Portal" />
          <html className="dental-background-color"/>
        </Helmet>
        <div className="dental-background-color">
          <div className="l-w1 m-projCopy animated">
          <h1 className="m-quote topper dental-lighttext-color">
         Modern<br/>insurance
          </h1>
          </div>
          <div className="l-w1 l-flexbox-alldevs projTitle">
    <div className="introList animated">
          <li className="abcd dental-surface-color dental-light-color">2017</li>
      <li className="abcd dental-surface-color dental-light-color">26 months</li>
      <li className="abcd dental-surface-color dental-light-color">Insurance</li>
      <li className="abcd dental-surface-color dental-light-color hidemobile">UX UI QA</li>
      <li className="abcd dental-surface-color dental-light-color hidemobile">E2E</li>

        </div>
      </div>
          <div className="l-flexbox l-w1 m-projCopy animated delay">
            <div className="m-twoColumnCopyA pad-Bot-60">

              <p className="m-projCopy-top dental-lighttext-color">
Delta Dental's UXR revealed need for a cost calculator, dentist finder, user dashboards, visualized billings and details, and appointment booking. This informed a new site map, E2E user flows, and responsive design system. <br/><br/>

As the sole designer, my work included creating a new site map, E2E user flows, and a responsive design system. I presented my work for review, delivered specs and user stories, maintained hotlists, and ran QA. Ongoing UX research informed immediate focus areas and aspirational features for our roadmap.</p>

            </div>


              </div>

          <div className="l-w1 animated delay ">
              <h4 className="m-projTitle small center dental-lighttext-color">Patient Features</h4>
              <div className="feature_set pad-Bot-60">
              <table>
    <tr>
        <th className="dental-surface-color st-dental "> <p className="table dental-light-color ">My <br/> Benefits </p></th>
        <th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Ask <br/> Dentists </p></th>
        <th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Find <br/> Dentists </p></th>
    </tr>
    <tr>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  "> Calc <br/> Costs </p></td>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  "> Request <br/> Appt </p></td>
        <td className="dental-surface-color st-dental"> <p className="table dental-light-color  "> Risk <br/> Review </p></td>
    </tr>
</table>
</div>
<h4 className="m-projTitle small center second dental-lighttext-color">Dentist Features</h4>
<div className="feature_set pad-Bot-60">
<table>
<tr>
<th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Manage <br/> Profile </p></th>
<th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Appt <br/> Scheduling </p></th>
<th className="dental-surface-color st-dental"> <p className="table dental-light-color "> Answer <br/> Questions </p></th>
</tr>
<tr>
<td className="dental-surface-color st-dental"> <p className="table dental-light-color "> View <br/> Analytics </p></td>
<td className="dental-surface-color st-dental"> <p className="table dental-light-color "> Submit <br/> Articles </p></td>
<td className="dental-surface-color st-dental"> <p className="table dental-light-color "> Link <br/> Profiles </p></td>
</tr>
</table>
</div>
            </div>

          <h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
            The landing page offers an entry point to both dentists and patients, with a shared login space for both user groups.
          </h1>
          {LandingFlow}
          {Images}
          <h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
            The dentist finder feature offers search results based on highly specific criteria, as well as ability to request appointments.
          </h1>
          <div className="l-flexbox m-webMockup">{FinderFlow}</div>
         <div className="l-flexbox m-webMockup">{Images24}</div>
         <h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
           The patient benefits dashboard acts as a hub for users to take a wide variety of actions
         </h1>
         <div className="l-flexbox m-webMockup">{BenefitsFlow}</div>
        <div className="l-flexbox m-webMockup">{Images46}</div>


          <div className="l-w0 m-mobileProjLink">
                  <Link to="/casestudy">
                  <button className="projectDetails no-background dental-surface-color m-b-60 m-bt-60">
                    <span className="button-text dental-light-color">Case Study</span> <span className="material-icons dental-light-color">arrow_forward</span>
                  </button>
                  </Link>
                </div>
<a id="section-three"></a>
<h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
  Dentists claim a profile using a unique identifier, then can add photos & info, using their profile to generate leads within the search function.
</h1>
<div className="l-flexbox m-webMockup">{ClaimFlow}</div>
<div className="l-flexbox m-webMockup">{Images1012}</div>

<h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
Below represents views of the hybrid web app, an aspect on our roadmap which hadn't realized
</h1>
          <div className="l-flexbox m-webMockup">{app1}</div>
          <div className="l-flexbox m-webMockup">{app2}</div>

          <h1 className="pullquote m-projTitle l-w2 dental-lighttext-color">
          UXR informed our immediate focus towards polish and new features: SSO, simplified profile setup, and ability to manage multiple profiles, as well as to aspirations including review collection, digital checkups, a utilization dashboard, and a hybrid web app.
          </h1>

          <div className="l-flexbox m-webMockup">{RoadMap}</div>

          <div className="l-w0 m-mobileProjLink">
                  <Link to="/ebay">
                  <button className="projectDetails no-background dental-surface-color m-projbutton">
                    <span className="button-text dental-light-color">Next Project</span> <span className="material-icons dental-light-color">arrow_forward</span>
                  </button>
                  </Link>

                </div></div>
      </Aux>
    );
  }
}

export default DentalOptimizer;
